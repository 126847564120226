.dashboard-container {
    background: linear-gradient(to bottom, #1a4731, #052e16);
    min-height: 100vh;
    padding: 1rem;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, sans-serif;
}

.profile-card {
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(8px);
    border-radius: 1.5rem;
    padding: 2rem;
    margin-bottom: 1.5rem;
    color: white;
    text-align: center;
}

.profile-info {
    margin-bottom: 0.5rem;
}

.user-role {
    font-size: 1rem;
    color: rgba(255, 255, 255, 0.9);
    margin: 0;
}

.time-display {
    text-align: center;
}

.current-time {
    font-size: 2.5rem;
    font-weight: 700;
    margin-bottom: 0.5rem;
}

.current-date {
    font-size: 1rem;
    color: rgba(255, 255, 255, 0.9);
}

.attendance-buttons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    margin-bottom: 1.5rem;
}

.attendance-btn {
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(8px);
    border: none;
    border-radius: 1.5rem;
    padding: 2rem 1rem;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;
}

.attendance-btn.datang {
    background: rgba(10, 255, 91, 0.2);
}

.attendance-btn.datang:hover:not(:disabled) {
    background: rgba(52, 211, 153, 0.3);
}

.attendance-btn.pulang {
    background: rgba(250, 205, 4, 0.938);
    color: black;
}

.attendance-btn.pulang:hover:not(:disabled) {
    background: rgba(196, 161, 9, 0.712);
}

.attendance-btn:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.btn-icon {
    width: 3.5rem;
    height: 3.5rem;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.btn-icon i {
    font-size: 2rem;
    color: white;
}

/* Add specific colors for the icons */
.datang .btn-icon i {
    color: rgb(52, 211, 153);
}

.pulang .btn-icon i {
    color: rgb(196, 13, 13);
}

.btn-icon {
    width: 3.5rem;
    height: 3.5rem;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.btn-icon i {
    font-size: 2rem;
    color: white;
}

.btn-text {
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
}

.btn-status {
    font-size: 0.875rem;
    color: rgba(255, 255, 255, 0.9);
}

.stats-card {
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(8px);
    border-radius: 1.5rem;
    padding: 1.5rem;
    margin-bottom: 5rem;
}

.stats-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1rem;
    text-align: center;
    color: white;
}

.stat-value {
    font-size: 1.75rem;
    font-weight: 700;
    margin-bottom: 0.25rem;
}

.stat-label {
    font-size: 0.875rem;
    color: rgba(255, 255, 255, 0.9);
}

.stats-header {
    color: white;
    font-size: 1.5rem;
}

.attendance-card {
    background-color: #15a9ff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 16px;
    margin: 16px 0;
}

.card-content {
    text-align: center;
}

.time-info {
    display: flex;
    justify-content: space-around;
    margin-top: 8px;
}

.time-item {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.time-item-ijin {
    display: flex;
    align-items: center;
}

.time-label-ijin {
    font-weight: bold;
    color: #555;
}


.time-label {
    font-weight: bold;
    color: #555;
}

.time-value {
    font-size: 2rem;
    color: #333;
}

.time-value-ijin {
    font-size: 2rem;
    color: #333;
}

.small-btn.ijin {
    background-color: #e70303;
    color: rgb(255, 243, 243);
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    font-size: 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    margin: 10px 0;
    width: 100%;
}

.small-btn.ijin:hover {
    background-color: #ffa500;
}

.small-btn.ijin:disabled {
    opacity: 0.6;
    cursor: not-allowed;
}

.small-btn.ijin i {
    font-size: 12px;
}


.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(119, 238, 198, 0.89);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background-color: rgb(217, 219, 217);
    padding: 20px;
    border-radius: 8px;
    width: 90%;
    max-width: 500px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.modal-content h2 {
    margin-bottom: 15px;
    color: #333;
    font-size: 1.2rem;
}

.ijin-textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-bottom: 15px;
    font-size: 1rem;
    resize: vertical;
}

.modal-buttons {
    display: flex;
    gap: 10px;
    justify-content: flex-end;
}

.cancel-btn,
.submit-btn {
    padding: 8px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
}

.cancel-btn {
    background-color: #e0e0e0;
    color: #333;
}

.submit-btn {
    background-color: #e70303;
    color: white;
}

.submit-btn:disabled {
    opacity: 0.6;
    cursor: not-allowed;
}

.ijin-select {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-bottom: 15px;
    font-size: 1rem;
    background-color: white;
    color: #333;
}

.ijin-select option {
    padding: 10px;
    background-color: white;
    color: #333;
}