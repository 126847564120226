.preloader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f0f0f0; /* Optional: background color */
}

.favicon-spinner {
  width: 50px; /* Adjust size as needed */
  height: 50px;
  background-image: url('../../../public/assets/img/icon_apk.png'); /* Use your favicon image */
  background-size: cover;
  border-radius: 50%;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

p {
  margin-top: 10px;
  font-size: 16px;
  color: #333; /* Adjust text color */
}